/**
 * Check if a value is null or undefined.
 *
 * @param object - The object to check.
 */
export function isNil<T>(
  object: T | null | undefined
): object is null | undefined {
  return object === null || object === undefined;
}

export function exists<T>(object: T | null | undefined): object is T {
  return !isNil(object);
}

export default isNil;
