import isNil from 'src/utils/is-nil';

/**
 * Recursively performs a deep equality check for two values
 *
 * @param a - The first value
 * @param b - The second value
 * @returns `true` if the two values are equivalent
 */
export function isEqual(a: unknown, b: unknown): boolean {
  if (isNil(a) || isNil(b)) {
    return a === b;
  }

  if (Array.isArray(a)) {
    return (
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((el, index) => isEqual(el, b[index]))
    );
  }

  if (typeof a === 'object') {
    if (typeof b !== 'object') {
      return false;
    }

    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const objA = a as Record<string, unknown>;
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const objB = b as Record<string, unknown>;

    return (
      Object.entries(objA).length === Object.entries(objB).length &&
      Object.entries(objA).every(([key, value]) => isEqual(value, objB[key]))
    );
  }

  return a === b;
}

export default isEqual;
